import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCategoryList() {
  // Use toast
  const toast = useToast()

  const refCategoriesListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: '#', key: 'id', sortable: true },
    { label: 'Categorie', key: 'name', sortable: true },
    { label: 'Omschrijving', key: 'description', sortable: false },
    { label: 'Acties', key: 'actions', sortable: false },
  ]
  const perPage = ref(10)
  const totalCategories = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(false)
  const showOverlay = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriesListTable.value ? refCategoriesListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalCategories.value,
    }
  })

  const refetchProductCategoryData = () => {
    refCategoriesListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchProductCategoryData()
  })

  const fetchCategories = (ctx, callback) => {
    store
      .dispatch('app-category-list/fetchProductCategories', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortDesc: isSortDirDesc.value,
        sortBy: sortBy.value,
      })
      .then(response => {
        const { items, _meta } = response.data

        callback(items)
        showOverlay.value = false
        totalCategories.value = _meta.totalCount
      })
      .catch(() => {
        showOverlay.value = false
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching product category list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchCategories,
    tableColumns,
    perPage,
    currentPage,
    totalCategories,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    refCategoriesListTable,
    refetchProductCategoryData,
    showOverlay,
  }
}
